import {useLazyQuery, useMutation} from '@apollo/client';
import {
    Mutation,
    Query,
    ServisniZakazkyGet,
    ServisniZakazkyGetTm,
    ServisniZakazkyMutationSetArgs,
    ServisniZakazkyQueryGetArgs,
    ServisniZakazkySetInput,
} from '@eon.cz/apollo13-graphql-web';
import {AccountCircleOutlined, InfoOutlined, PhoneOutlined} from '@mui/icons-material';
import {Box, Button, Grid, InputAdornment, Link, TextField, Typography} from '@mui/material';
import {format} from 'date-fns';
import {cs} from 'date-fns/locale/cs';
import {ChangeEvent, FormEvent, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {ActionButton} from '../../common/components/buttons/ActionButton';
import {LoadingDialog} from '../../common/components/dialogs/LoadingDialog';
import {SentIcon} from '../../common/components/icons/SentIcon';
import {TooltipIcon} from '../../common/components/icons/TooltipIcon';
import {PaperBlock} from '../../common/components/layout/PaperBlock';
import {NotificationType} from '../../common/components/notifications/NotificationModel';
import {Div} from '../../common/components/styledComponents/Div';
import {Form} from '../../common/components/styledComponents/Form';
import {Mutations} from '../../graphql/Mutations';
import {Queries} from '../../graphql/Queries';
import {useAppContext} from '../../lib/context/AppContext';
import {EndModal} from '../dialog/EndModal';
import {formatHours} from '../service/ServisniZakazkyService';
import {validationSchemaTermin} from '../validations/EntryModalValidation';
import {useFormValidator} from '../validations/Validation';
type Props = {
    readonly servisniZakazky: ServisniZakazkyGet | undefined;
};
export const Body = ({servisniZakazky: getZakazky}: Props) => {
    // context
    const {
        values: {pin},
        addNotification,
    } = useAppContext();

    // 3rd party methods
    const intl = useIntl();

    // local state
    const [endModal, setEndModal] = useState<{date: string | null; time: string | null} | null>(null);
    const [termin, setTermin] = useState<{datumOd: string; datumDo: string; priorita: string | undefined | null} | null>(null);
    const [telefon, setTelefon] = useState('+420');
    const poznamka = useRef<HTMLInputElement>(null);

    // apollo query
    const [overitSZ, {loading: loadingOvereni, data}] = useLazyQuery<Query, ServisniZakazkyQueryGetArgs>(Queries.gql.overit, {
        onError: (err) => {
            addNotification({type: NotificationType.ERROR, text: err.message});
        },
    });

    const servisniZakazky = data?.servisniZakazky?.get ?? getZakazky;

    // local variables
    const zaplanovanyTermin = servisniZakazky?.terminy?.reduce(
        (acc, termin) => (termin?.zaplanovany ? {datumOd: termin?.datumOd, datumDo: termin?.datumDo, priorita: termin?.priorita} : acc),
        {} as Omit<ServisniZakazkyGetTm, 'zaplanovany'>,
    );

    /* Checking if the start and end dates are the same hour, and if they are, it returns the string
    "cely den" (whole day), otherwise it returns the start and end times. */
    const hours = zaplanovanyTermin?.datumOd ? formatHours(zaplanovanyTermin?.datumOd, zaplanovanyTermin?.datumDo, intl, zaplanovanyTermin.priorita) : '-';

    /* Remapping the array of objects to an object of arrays. */
    const remapToDateObject = servisniZakazky?.terminy?.reduce(
        (acc, curr) => {
            const termin = {...acc};
            const key = curr?.datumOd?.substring(0, 10);
            if (!termin[key]) {
                Object.assign(termin, {[key]: []});
            }

            !curr?.zaplanovany && termin[key]?.push(curr as ServisniZakazkyGetTm);
            return termin;
        },
        {} as {[key: string]: ServisniZakazkyGetTm[]},
    );

    const isMoreThenOneDay =
        remapToDateObject && Object.keys(remapToDateObject).length === 1
            ? Object.entries(remapToDateObject).reduce((_, [, value]) => value.length !== 1, false)
            : true;

    // Form validator
    const {blurHandler, changeHandler, errors, texts, validate} = useFormValidator(validationSchemaTermin(telefon));

    // mutations
    const [odeslatTermin, {loading}] = useMutation<Mutation, ServisniZakazkyMutationSetArgs>(Mutations.gql.ulozitTermin, {
        onError: (err) => {
            err.graphQLErrors.forEach((e) => addNotification({type: NotificationType.ERROR, text: (e as any).errors[0].message}));
            const variables = {
                input: {
                    pin: pin as string,
                    cisloServisniZakazky: servisniZakazky?.cisloServisniZakazky as string,
                    clientId: window.sessionStorage.getItem('clientId') || '',
                },
            };
            overitSZ({variables});
        },
    });

    // local methods
    const handleChangeTermin = (termin: {datumOd: string; datumDo: string; priorita: string | undefined | null} | null) => () => setTermin(termin);

    const handleChangeTelefon = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const telefon = e.target.value.length === 0 ? '' : e.target.value;
        setTelefon(telefon);
        changeHandler(e);
    };
    const doLogin = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const input = await validate(Object.fromEntries(new FormData(event.currentTarget)));

        if (input === null) return;
        const selectedTermin = termin ?? zaplanovanyTermin;
        const telefon = input?.mobilniTelefon ?? '';
        const variables = {
            input: {
                ...input,
                ...selectedTermin,
                mobilniTelefon: /(00420|\+420|00421|\+421)/.test(telefon.trim()) ? undefined : telefon,
                clientId: window.sessionStorage.getItem('clientId') || '',
                eanEic: servisniZakazky?.eanEic,
                pin,
                cisloServisniZakazky: servisniZakazky?.cisloServisniZakazky,
            } as ServisniZakazkySetInput,
        };

        setEndModal({
            date: `${format(new Date(selectedTermin?.datumOd), 'EEEE dd.MM.yyyy', {locale: cs})}`,
            time: formatHours(selectedTermin?.datumOd, selectedTermin?.datumDo, intl, selectedTermin?.priorita),
        });
        odeslatTermin({variables});
    };

    return (
        <PaperBlock titleBlock="planovani.terminu" titleBlockIcon={<InfoOutlined />}>
            {(loading || loadingOvereni) && <LoadingDialog open />}
            <Grid container>
                <Grid item xs={12} display="flex" alignItems="center" sx={{marginBottom: 4}}>
                    <Typography component="div">
                        <Box sx={{fontWeight: 'bold'}}>
                            <FormattedMessage id="naplanovana.zakazka" />
                        </Box>
                    </Typography>
                    <Button
                        sx={{
                            borderRadius: 2,
                            marginLeft: 2,
                            textTransform: 'none',
                        }}
                        onClick={handleChangeTermin(null)}
                        color={!!termin || !!servisniZakazky?.zmenaTerminu || !zaplanovanyTermin?.datumOd ? 'inherit' : 'primary'}
                        variant="contained"
                    >
                        {zaplanovanyTermin?.datumOd
                            ? `${format(new Date(zaplanovanyTermin?.datumOd), 'EEEE dd.MM.yyyy', {locale: cs})} ${hours}`
                            : 'NEZAPLÁNOVÁN'}
                    </Button>
                </Grid>

                <Grid item xs={12} display="flex" alignItems="center">
                    <Typography>
                        <FormattedMessage
                            id="naplanovana.zakazka.subtitle"
                            values={{
                                more: intl.formatMessage({
                                    id: isMoreThenOneDay ? 'naplanovana.zakazka.more' : 'naplanovana.zakazka.no.more',
                                }),
                            }}
                        />
                    </Typography>
                </Grid>
                {isMoreThenOneDay ? (
                    <>
                        <Grid container sx={{marginTop: 5}}>
                            <Grid item xs={12} sm={1}>
                                <Typography component="div">
                                    <Box sx={{fontWeight: 'bold'}}>
                                        <FormattedMessage id="legenda" />
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2} display="flex" alignItems="center">
                                <Box
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor: 'primary.main',
                                        marginRight: 2,
                                    }}
                                />
                                <Typography>
                                    <FormattedMessage id="naplanovany.termin" />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2} display="flex" alignItems="center">
                                <Box
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor: 'custom.green.main',
                                        marginRight: 2,
                                    }}
                                />
                                <Typography>
                                    <FormattedMessage id="preferovany.termin" />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={2} display="flex" alignItems="center">
                                <Box
                                    sx={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor: 'lightgrey',
                                        marginRight: 2,
                                    }}
                                />
                                <Typography>
                                    <FormattedMessage id="volny.termin" />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container display="flex" flexDirection="row" justifyContent="space-evenly" padding={4}>
                            {Object.entries(remapToDateObject ?? {})
                                ?.filter(([key, value]) => key !== 'undefined' && value.length > 0)
                                ?.map(([key, value], index) => {
                                    return (
                                        <Div key={`${key}-${index}`}>
                                            <Grid item sx={{textAlign: 'center', fontWeight: 'bold'}}>
                                                {key ? format(new Date(key), 'EEEE dd.MM.yyyy', {locale: cs}) : '-'}
                                            </Grid>
                                            {value
                                                .filter(({zaplanovany}) => !zaplanovany)
                                                .map(({datumOd, datumDo, priorita}, index) => (
                                                    <Grid container key={`${datumOd}-${index}`} display="flex" alignItems="center">
                                                        <Grid item padding={1}>
                                                            <Button
                                                                sx={{
                                                                    backgroundColor: priorita === 'P' || priorita === 'R' ? 'custom.green.main' : 'lightgrey',
                                                                    ...(datumOd === termin?.datumOd &&
                                                                        datumDo === termin?.datumDo && {
                                                                            backgroundColor: undefined,
                                                                        }),
                                                                    borderRadius: 2,
                                                                    width: 180,
                                                                }}
                                                                onClick={handleChangeTermin({datumOd, datumDo, priorita})}
                                                                color={
                                                                    datumOd === termin?.datumOd && datumDo === termin?.datumDo
                                                                        ? 'primary'
                                                                        : priorita !== 'P' && priorita !== 'R'
                                                                          ? 'inherit'
                                                                          : 'success'
                                                                }
                                                                variant="contained"
                                                            >
                                                                {formatHours(datumOd, datumDo, intl, priorita)}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                        </Div>
                                    );
                                })}
                        </Grid>
                    </>
                ) : null}

                <Form onSubmit={doLogin} noValidate sx={{width: '100%'}}>
                    <Grid container padding={4}>
                        <Grid item xs={12} sm={6}>
                            <TooltipIcon text="telefon.tooltip" placement="top" color="primary" fontSize="small">
                                <TextField
                                    value={telefon}
                                    margin="dense"
                                    autoComplete="tel"
                                    name="mobilniTelefon"
                                    label="Telefon"
                                    type="text"
                                    sx={{width: '90%'}}
                                    variant="standard"
                                    error={errors('mobilniTelefon')}
                                    helperText={texts('mobilniTelefon')}
                                    onChange={handleChangeTelefon}
                                    onBlur={blurHandler}
                                    InputLabelProps={{
                                        shrink: !!telefon,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneOutlined />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </TooltipIcon>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                inputRef={poznamka}
                                margin="dense"
                                name="poznamka"
                                label="Jméno a Příjmení"
                                type="text"
                                sx={{width: '90%'}}
                                variant="standard"
                                error={errors('poznamka')}
                                helperText={texts('poznamka')}
                                onChange={changeHandler}
                                onBlur={blurHandler}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircleOutlined />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <ActionButton variant="contained" type="submit" caption="button.sent" icon={<SentIcon />} />
                    </Grid>
                </Form>

                <Grid item xs={12} sx={{marginTop: 4, marginBottom: 4}}>
                    <Typography>
                        <FormattedMessage
                            id="naplanovana.zakazka.info"
                            values={{
                                email: <Link href={`mailto:${intl.formatMessage({id: 'support.email'})}`}>{intl.formatMessage({id: 'support.email'})}</Link>,
                                telefon: (
                                    <Typography component="span" color="primary">
                                        <FormattedMessage id="support.telefon" />
                                    </Typography>
                                ),
                            }}
                        />
                    </Typography>
                </Grid>
            </Grid>
            {endModal && !loading && <EndModal data={endModal} />}
        </PaperBlock>
    );
};
