import {FormattedMessage} from 'react-intl';
import * as Yup from 'yup';

// Common setup validations
Yup.setLocale({
    mixed: {
        required: () => <FormattedMessage id="yup.required" />,
    },
    string: {
        max: ({max}: {max: number}) => <FormattedMessage id="yup.max" values={{znak: max, pismeno: max === 1 ? '' : max > 1 && max < 5 ? 'y' : 'ů'}} />,
        min: ({min}: {min: number}) => <FormattedMessage id="yup.min" values={{znak: min, pismeno: min === 1 ? '' : min > 1 && min < 5 ? 'y' : 'ů'}} />,
    },
});

const string = Yup.string();
const number = string.matches(/^\d+$/, () => <FormattedMessage id="yup.number" />);

const telefonCZ = string.matches(/^(\+420|00420)??[6-7][0-9]{2}?[0-9]{3}?[0-9]{3}$/, () => <FormattedMessage id="yup.phone.cz" />);
const telefonSK = string.matches(/^(\+421|00421)??[9][0-9]{2}?[0-9]{3}?[0-9]{3}$/, () => <FormattedMessage id="yup.phone.sk" />);

const validateTelefon = (telefon: string) => {
    if (telefon?.length === 0) {
        return string.nullable();
    }
    if (/^(00420|\+420|00421|\+421)$/.test(telefon)) {
        return string.nullable();
    }

    if (/^\d+$/.test(telefon)) {
        return string.test(
            '',
            () => <FormattedMessage id="yup.phone.not.correct" />,
            () => {
                return false;
            },
        );
    }

    return /00420|\+420/.test(telefon) ? telefonCZ : telefonSK;
};
export const validationSchemaEntryModal = Yup.object().shape({
    cisloServisniZakazky: number.required().max(12),
    pin: number.required().max(6),
});
export const validationSchemaTermin = (telefon: string) =>
    Yup.object().shape({
        mobilniTelefon: Yup.lazy(() => validateTelefon(telefon)),
        poznamka: string
            .transform((val) => (val === '' ? null : val))
            .nullable()
            .max(70)
            .matches(/^[a-zA-Zá-žÁ-Ž ]+$/, () => <FormattedMessage id="yup.string.only" values={{pole: 'Jméno a Příjmení'}} />),
    });
