import {Button, CircularProgress, Icon} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {ActionButtonProps} from './ButtonProps';

/**
 * General action button which has "running" state
 */
export const ActionButton = ({caption, running, disabled, icon, ...props}: ActionButtonProps) => {
    const iconComponent = typeof icon === 'string' ? <Icon>{icon}</Icon> : icon;
    return (
        <FormattedMessage id={caption}>
            {(msg) => (
                <Button disabled={disabled || running} {...props}>
                    {running ? <CircularProgress size={20} /> : iconComponent}
                    &nbsp;&nbsp;
                    {msg}
                </Button>
            )}
        </FormattedMessage>
    );
};
