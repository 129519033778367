import {FC, ReactNode} from 'react';
import {Span} from '../styledComponents/Span';

type Props = {
    readonly color?: string;
    readonly children: ReactNode | string | any;
};

export const DetailValueBadge: FC<Props> = ({color, children}) => (
    <Span
        sx={(theme) => ({
            borderRadius: 5,
            padding: '0px 8px',
            display: 'inline-block',
            ...(color === 'primary' && {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            }),
            ...(color === 'secondary' && {
                bbackgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText,
            }),
            ...(color === 'grey' && {
                backgroundColor: theme.palette.grey[500],
                color: theme.palette.error.contrastText,
            }),
            ...(color === 'error' && {
                backgroundColor: theme.palette.error.main,
                color: theme.palette.common.white,
            }),
            ...(color === 'green' && {
                backgroundColor: theme.palette.custom.green.main,
                color: theme.palette.custom.green.contrastText,
            }),
            ...(color === 'orange' && {
                backgroundColor: theme.palette.custom.orange.main,
                color: theme.palette.custom.orange.contrastText,
            }),
            ...(color === 'brown' && {
                backgroundColor: theme.palette.custom.brown.main,
                color: theme.palette.custom.brown.contrastText,
            }),
        })}
    >
        {children}
    </Span>
);
