import {AccessTimeOutlined, LogoutOutlined} from '@mui/icons-material';
import {DialogActions, Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {useRouter} from 'next/router';
import {FormattedMessage} from 'react-intl';
import {ActionButton} from '../../common/components/buttons/ActionButton';
import {CalendarIcon} from '../../common/components/icons/CalendarIcon';
import {CircleCheckIcon} from '../../common/components/icons/CircleCheckIcon';
import {Div} from '../../common/components/styledComponents/Div';
import {Span} from '../../common/components/styledComponents/Span';

type Props = {
    readonly data: {date: string | null; time: string | null};
};
export const EndModal = ({data}: Props) => {
    // 3rd party libraries
    const {push} = useRouter();
    const handleRouteEGD = () => push('https://www.egd.cz');
    return (
        <Dialog
            sx={{
                backdropFilter: 'blur(5px)',
            }}
            open={true}
            disableEscapeKeyDown
            aria-labelledby="responsive-dialog-title"
        >
            <DialogContent sx={{textAlign: 'center'}}>
                <CircleCheckIcon color="success" sx={{fontSize: '4rem'}} />
                <DialogContentText>
                    <Typography variant="h5" component="span">
                        <FormattedMessage id="potvrzeni" />
                    </Typography>
                </DialogContentText>
                <Div sx={{display: 'flex', justifyContent: 'space-around'}}>
                    <Span sx={{display: 'flex', fontSize: '1.5rem', alignItems: 'center'}}>
                        <CalendarIcon color="primary" sx={{fontSize: '2rem', marginRight: 2}} />
                        {data?.date}
                    </Span>
                    <Span sx={{display: 'flex', fontSize: '1.5rem', alignItems: 'center'}}>
                        <AccessTimeOutlined color="primary" sx={{fontSize: '2rem', marginRight: 2}} />
                        {data?.time}
                    </Span>
                </Div>
            </DialogContent>
            <DialogActions>
                <ActionButton onClick={handleRouteEGD} caption="button.back" icon={<LogoutOutlined />} />
            </DialogActions>
        </Dialog>
    );
};
