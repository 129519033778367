import {Komodita, ServisniZakazkyGet} from '@eon.cz/apollo13-graphql-web';
import {InfoOutlined} from '@mui/icons-material';
import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import {FormattedMessage} from 'react-intl';
import {DetailValue} from '../../common/components/detail/DetailValue';
import {PaperBlock} from '../../common/components/layout/PaperBlock';

type Props = {
    readonly servisniZakazky: ServisniZakazkyGet | undefined;
};
export const Header = ({servisniZakazky}: Props) => {
    const subtitle = servisniZakazky?.komodita === Komodita.ELEKTRINA ? 'ean.subtitle' : 'eic.subtitle';
    const komoditaTitle = servisniZakazky?.komodita === Komodita.ELEKTRINA ? 'ean' : 'eic';
    return (
        <PaperBlock titleBlock="zakladni.informace" titleBlockIcon={<InfoOutlined />}>
            <Grid container>
                <Grid item xs={12}>
                    <DetailValue inline title="adresa" value={servisniZakazky?.adresaOm} />
                </Grid>
                <Grid item xs={12}>
                    <DetailValue inline title={komoditaTitle} value={servisniZakazky?.eanEic} />
                </Grid>
                <Grid item xs={12}>
                    <DetailValue inline title="duvod" value={servisniZakazky?.duvodSz} />
                </Grid>
                <Grid item xs={12}>
                    <DetailValue inline title="cislo" value={servisniZakazky?.cisloServisniZakazky} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">
                        <FormattedMessage id={subtitle} />
                    </Typography>
                </Grid>
            </Grid>
        </PaperBlock>
    );
};
