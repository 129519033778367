import {ErrorOutline as ErrorIcon, Warning as WarningIcon} from '@mui/icons-material';
import {Box, Link, Tooltip, Typography} from '@mui/material';
import {FC, ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import {TooltipIcon} from '../icons/TooltipIcon';
import {Span} from '../styledComponents/Span';
import {DetailValueBadge} from './DetailValueBadge';

const ValidationErrorType = {
    CRITICAL: 'CRITICAL',
    WARNING: 'WARNING',
    CUSTOM: 'CUSTOM',
} as const;

type ValidationErrorType = typeof ValidationErrorType[keyof typeof ValidationErrorType];
/**
 * Validation error (definována jako ValidationError)
 */
type DetailValueError = {
    readonly type: ValidationErrorType;
    readonly message?: ReactNode;
};

type DetailValueProps = {
    readonly title: string;
    readonly value?: ReactNode | string | number | null | any;
    readonly tooltipValue?: ReactNode | string;
    readonly hideIfEmpty?: boolean;
    readonly tooltipText?: string;
    readonly hover?: boolean;
    readonly highlight?: boolean;
    readonly inline?: boolean;
    readonly color?: string;
    readonly errorMessage?: ReactNode;
    readonly validationErrors?: DetailValueError | DetailValueError[];
    readonly onClick?: () => void;
};

const processErrorsAndWarnings = (errorMessage?: ReactNode, validationErrors?: DetailValueError | DetailValueError[]) => {
    const errors: ReactNode[] = [];
    const warnings: ReactNode[] = [];

    if (errorMessage) {
        errors.push(errorMessage);
    }

    if (validationErrors) {
        const vea: DetailValueError[] = (validationErrors as DetailValueError[]).length
            ? (validationErrors as DetailValueError[])
            : [validationErrors as DetailValueError];
        vea.forEach((ve) => {
            if (ve.type === ValidationErrorType.CRITICAL) {
                errors.push(ve.message);
            }
            if (ve.type === ValidationErrorType.WARNING) {
                warnings.push(ve.message);
            }
        });
    }

    return {errors, warnings};
};

export const DetailValue: FC<DetailValueProps> = ({
    title,
    value,
    hideIfEmpty,
    tooltipText,
    onClick,
    hover,
    highlight,
    color,
    errorMessage,
    validationErrors,
    tooltipValue,
    inline,
}) => {
    const isEmpty = value === undefined || value === null || value === '';

    if (isEmpty && hideIfEmpty) {
        return <span />;
    }

    const handleOnClick = () => {
        if (onClick) {
            onClick();
        }
    };

    const getValue = () => {
        const val = highlight ? <DetailValueBadge color={color}>{!isEmpty ? value : '-'}</DetailValueBadge> : !isEmpty ? value : '-';
        return (
            <Link
                sx={(theme) => ({
                    ...(hover
                        ? {
                              padding: `1px 2px`,
                              borderRadius: 4,
                              backgroundColor: theme.palette.grey[200],
                              cursor: 'pointer',
                              color: theme.palette.primary.main,
                              '&:hover': {
                                  textDecoration: 'underline',
                                  textDecorationStyle: 'dashed',
                              },
                          }
                        : undefined),
                })}
            >
                {val}
            </Link>
        );
    };

    const inlineValue = () => {
        const val = highlight ? <DetailValueBadge color={color}>{!isEmpty ? value : '-'}</DetailValueBadge> : !isEmpty ? value : '-';
        return <Typography color="primary">{`: ${val}`}</Typography>;
    };

    const val = inline ? inlineValue : getValue;

    const {errors, warnings} = processErrorsAndWarnings(errorMessage, validationErrors);

    const hasErrors = errors.length > 0;
    const hasWarnings = warnings.length > 0;

    return (
        <Box
            role="presentation"
            sx={{
                marginBottom: '.5em',
                display: inline ? 'flex' : 'block',
            }}
            onClick={handleOnClick}
            onKeyPress={handleOnClick}
        >
            <FormattedMessage id={title}>
                {(msg) => (
                    <Typography
                        component="div"
                        sx={{
                            color: 'text.secondary',
                            fontSize: inline ? '100%' : '80%',
                        }}
                    >
                        {msg}
                    </Typography>
                )}
            </FormattedMessage>
            <Typography component="span" variant="body2">
                <Span
                    sx={{
                        ...(!hasErrors &&
                            !hasWarnings && {
                                maxWidth: '100%',
                            }),

                        ...((hasWarnings || hasErrors) && {
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                            maxWidth: 'calc(100% - 27px)',
                        }),
                        ...(hasErrors &&
                            hasWarnings && {
                                maxWidth: 'calc(100% - 54px)',
                            }),
                    }}
                >
                    {tooltipText ? (
                        <TooltipIcon text={tooltipText} value={tooltipValue} placement="bottom-start">
                            {val()}
                        </TooltipIcon>
                    ) : (
                        val()
                    )}
                </Span>
                {hasErrors && (
                    <Tooltip
                        title={
                            <div>
                                {errors.map((node, i) => (
                                    <div key={i}>{node}</div>
                                ))}
                            </div>
                        }
                        leaveDelay={2000}
                    >
                        <ErrorIcon
                            sx={{
                                color: 'error.main',
                                margin: '-3px 0 -7px 4px',
                            }}
                        />
                    </Tooltip>
                )}
                {hasWarnings && (
                    <Tooltip
                        title={
                            <div>
                                {warnings.map((node, i) => (
                                    <div key={i}>{node}</div>
                                ))}
                            </div>
                        }
                        leaveDelay={2000}
                    >
                        <WarningIcon
                            sx={{
                                color: 'custom.orange.main',
                                margin: '-3px 0 -7px 4px',
                            }}
                        />
                    </Tooltip>
                )}
            </Typography>
        </Box>
    );
};
