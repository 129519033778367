import {ChangeEvent, FocusEvent, useCallback, useEffect, useState} from 'react';
import * as Yup from 'yup';

/**
 * Form validator state field
 */
type FormValidatorStateField = {
    /**
     * Is error state
     */
    readonly error: boolean;

    /**
     * state text
     */
    readonly text: string;
};

/**
 * Form validator state fields
 */
type FormValidatorStateFields = {
    [key: string]: FormValidatorStateField | undefined;
};

/**
 * Form validatior
 * @param schemas Initial validation schemas
 * @param milliseconds Merge change update interval
 */
export const useFormValidator = <T extends Yup.AnyObjectSchema>(schemas: T, milliseconds = 200) => {
    // useState init
    const defaultState: FormValidatorStateFields = {};
    const [state, updateState] = useState<FormValidatorStateFields>(defaultState);

    // Change timeout seed
    let changeSeed = 0;

    // Change value handler
    const commitChange = (field: string, value: string) => {
        (Yup.reach(schemas, field, value) as Yup.Schema)
            .validate(value)
            .then((result: any) => {
                commitResult(field, result);
            })
            .catch((result: any) => {
                commitResult(field, result);
            });
    };

    // Commit state result
    const commitResult = (field: string, result: any) => {
        const currentItem = state[field];
        if (result instanceof Yup.ValidationError) {
            // Error
            if (currentItem) {
                // First to avoid same result redraw
                if (currentItem.error && currentItem.text == result.message) return;

                // Update state
                Object.assign(currentItem, {error: true, text: result.message});
            } else {
                // New item
                const newItem: FormValidatorStateField = {
                    error: true,
                    text: result.message,
                };
                updateState((prevState) => ({...prevState, [field]: newItem}));
            }
        } else {
            // Success and no result, just continue
            if (currentItem == null) return;

            // Delete current state result
            updateState((prevState) => ({...prevState, [field]: undefined}));
        }
    };

    // Clear timeout seed
    const clearSeed = useCallback(() => {
        if (changeSeed > 0) clearTimeout(changeSeed);
    }, [changeSeed]);

    // Delay change
    const delayChange = (field: string, value: string) => {
        clearSeed();

        changeSeed = Number(
            setTimeout(() => {
                commitChange(field, value);
            }, milliseconds),
        );
    };

    // Merge into the life cycle
    useEffect(() => {
        return () => {
            // clearTimeout before dispose the view
            clearSeed();
        };
    }, [clearSeed]);

    // Return methods for manipulation
    return {
        /**
         * Input or Textarea blur handler
         * @param event Focus event
         */
        blurHandler: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const {name, value} = event.currentTarget;
            delayChange(name, value);
        },

        /**
         * Input or Textarea change handler
         * @param event Change event
         */
        changeHandler: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const {name, value} = event.currentTarget;
            delayChange(name, value);
        },

        /**
         * Commit change
         */
        commitChange,

        /**
         * State error or not
         * @param field Field name
         */
        errors: (field: string) => {
            return state[field]?.error;
        },

        /**
         * State text
         * @param field Field name
         */
        texts: (field: string) => {
            return state[field]?.text;
        },

        /**
         * Validate form data
         * @param data form data, Object.fromEntries(new FormData(form))
         */
        validate: async (data: unknown) => {
            try {
                clearSeed();
                return await schemas.validate(data, {strict: false, abortEarly: false, stripUnknown: false});
            } catch (e) {
                // Reset
                const newState: FormValidatorStateFields = {};
                // Iterate the error items
                if (e instanceof Yup.ValidationError) {
                    for (const error of e.inner) {
                        // Only show the first error of the field
                        if (newState[error.path ?? ''] == null) {
                            // New item
                            const newItem: FormValidatorStateField = {
                                error: true,
                                text: error.message,
                            };
                            Object.assign(newState, {[error.path ?? '']: newItem});
                        }
                    }
                }

                // Update state
                updateState(newState);
            }

            return null;
        },
    };
};
