import {gql} from '@apollo/client';

export const Queries = {
    gql: {
        overit: gql`
            query OveritServisniZakazkyQuerie($input: ServisniZakazkyGetInput!) {
                servisniZakazky {
                    get(input: $input) {
                        adresaOm
                        cisloServisniZakazky
                        duvodSz
                        duvodSzKod
                        eanEic
                        komodita
                        terminy {
                            datumDo
                            datumOd
                            priorita
                            zaplanovany
                        }
                        zmenaTerminu
                    }
                }
            }
        `,
    },
};
