import {SvgIcon, SvgIconProps} from '@mui/material';
import {FC} from 'react';

type Props = SvgIconProps;

export const CalendarIcon: FC<Props> = (props) => (
    <SvgIcon {...props}>
        <path d="M 21.911 16.006 C 22.016 15.573 22.07 15.128 22.071 14.681 L 22.071 8.931 C 22.068 5.757 19.495 3.184 16.322 3.181 L 15.603 3.181 L 15.603 1.025 L 14.165 1.025 L 14.165 3.181 L 12.009 3.181 L 12.009 1.025 L 10.572 1.025 L 10.572 3.181 L 8.415 3.181 L 8.415 1.025 L 6.978 1.025 L 6.978 3.181 L 6.259 3.181 C 3.085 3.184 0.512 5.757 0.509 8.931 L 0.509 14.681 C 0.512 17.855 3.085 20.427 6.259 20.43 L 16.322 20.43 C 16.42 20.43 16.518 20.421 16.616 20.416 C 17.711 22.956 21.145 23.358 22.797 21.14 C 24.026 19.491 23.622 17.148 21.911 16.006 Z M 16.322 18.993 L 6.259 18.993 C 3.879 18.991 1.949 17.062 1.947 14.681 L 1.947 11.088 L 17.04 11.088 C 17.593 11.088 17.939 10.489 17.662 10.009 C 17.534 9.787 17.297 9.65 17.04 9.65 L 1.947 9.65 L 1.947 8.931 C 1.949 6.551 3.879 4.621 6.259 4.619 L 6.978 4.619 L 6.978 6.775 L 8.415 6.775 L 8.415 4.619 L 10.572 4.619 L 10.572 6.775 L 12.009 6.775 L 12.009 4.619 L 14.165 4.619 L 14.165 6.775 L 15.603 6.775 L 15.603 4.619 L 16.322 4.619 C 18.701 4.621 20.631 6.551 20.633 8.931 L 20.633 14.681 C 20.633 14.944 20.607 15.206 20.558 15.464 L 20.558 15.464 C 18.347 15.036 16.3 16.742 16.322 18.993 Z M 20.955 17.717 L 20.275 19.078 L 20.275 20.43 C 20.275 20.707 19.975 20.88 19.735 20.742 C 19.624 20.678 19.556 20.559 19.556 20.43 L 19.556 18.993 L 19.558 18.982 C 19.56 18.958 19.565 18.935 19.571 18.912 C 19.575 18.888 19.582 18.865 19.589 18.843 L 19.593 18.832 L 20.312 17.395 C 20.436 17.148 20.782 17.127 20.933 17.358 C 21.004 17.464 21.013 17.602 20.955 17.717 Z" />
    </SvgIcon>
);
