import {useLazyQuery} from '@apollo/client';
import {Query, ServisniZakazkyQueryGetArgs} from '@eon.cz/apollo13-graphql-web';
import {LogoutOutlined, NumbersOutlined, PinOutlined} from '@mui/icons-material';
import {InputAdornment, TextField, Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useRouter} from 'next/router';
import {FormEvent, useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {ActionButton} from '../../common/components/buttons/ActionButton';
import {LoadingDialog} from '../../common/components/dialogs/LoadingDialog';
import {SentIcon} from '../../common/components/icons/SentIcon';
import {NotificationType} from '../../common/components/notifications/NotificationModel';
import {Queries} from '../../graphql/Queries';
import {useAppContext} from '../../lib/context/AppContext';
import {validationSchemaEntryModal} from '../validations/EntryModalValidation';
import {useFormValidator} from '../validations/Validation';

export const EntryModal = () => {
    // context
    const {setContextValues, addNotification} = useAppContext();

    // 3rd party libraries
    const {push} = useRouter();

    // local state
    const [open, setOpen] = useState(true);
    const servisniPrace = useRef('');
    const pin = useRef('');

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.sessionStorage.setItem('clientId', Math.random().toString(36).substring(2, 9));
        }
    }, []);

    // Form validator
    const {blurHandler, changeHandler, errors, texts, validate} = useFormValidator(validationSchemaEntryModal);

    // apollo query
    const [overitSZ, {loading}] = useLazyQuery<Query, ServisniZakazkyQueryGetArgs>(Queries.gql.overit, {
        onCompleted: (res) => {
            setOpen(false);
            setContextValues?.({servisniZakazky: res.servisniZakazky.get});
        },
        onError: (err) => {
            addNotification({type: NotificationType.ERROR, text: err.message});
        },
    });

    if (loading) {
        return <LoadingDialog open />;
    }

    // local methods
    const doLogin = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const input = await validate(Object.fromEntries(new FormData(event.currentTarget)));
        if (input === null) return;
        setContextValues?.({pin: input.pin});
        const variables = {input: {...input, clientId: window.sessionStorage.getItem('clientId') || ''}};
        overitSZ({variables});
    };
    const handleRouteEGD = () => push('https://www.egd.cz');

    return (
        <Dialog
            sx={{
                backdropFilter: 'blur(5px)',
            }}
            open={open}
            disableEscapeKeyDown
            aria-labelledby="responsive-dialog-title"
        >
            <form onSubmit={doLogin} noValidate>
                <DialogTitle id="responsive-dialog-title">
                    <Typography variant="inherit">
                        <FormattedMessage id="entry.modal.header" />
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="entry.modal.title" />
                    </DialogContentText>

                    <TextField
                        inputRef={servisniPrace}
                        autoFocus
                        margin="dense"
                        name="cisloServisniZakazky"
                        label="Servisní práce"
                        type="text"
                        fullWidth
                        variant="standard"
                        error={errors('cisloServisniZakazky')}
                        helperText={texts('cisloServisniZakazky')}
                        onChange={changeHandler}
                        onBlur={blurHandler}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <NumbersOutlined />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        inputRef={pin}
                        margin="dense"
                        name="pin"
                        label="PIN"
                        type="text"
                        fullWidth
                        variant="standard"
                        error={errors('pin')}
                        helperText={texts('pin')}
                        onChange={changeHandler}
                        onBlur={blurHandler}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PinOutlined />
                                </InputAdornment>
                            ),
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <ActionButton onClick={handleRouteEGD} caption="button.back" icon={<LogoutOutlined />} />
                    <ActionButton type="submit" caption="button.sent" icon={<SentIcon />} />
                </DialogActions>
            </form>
        </Dialog>
    );
};
