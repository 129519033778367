import {SvgIcon, SvgIconProps} from '@mui/material';
import {FC} from 'react';

type Props = SvgIconProps;

export const CircleCheckIcon: FC<Props> = (props) => (
    <SvgIcon {...props} width={props.width ?? '18px'} height={props.height ?? '18px'}>
        <path d="M 11.549 21.43 C 3.86 21.427 -0.941 13.103 2.905 6.446 C 5.686 1.632 11.865 0.018 16.648 2.854 C 17.018 3.075 17.01 3.613 16.633 3.822 C 16.46 3.92 16.247 3.917 16.076 3.816 C 10.209 0.336 2.776 4.513 2.698 11.333 C 2.619 18.155 9.952 22.503 15.899 19.161 C 20.144 16.775 21.668 11.407 19.31 7.146 C 19.098 6.771 19.372 6.308 19.801 6.312 C 20.004 6.314 20.189 6.426 20.286 6.603 C 23.971 13.26 19.158 21.427 11.549 21.43 Z" />
        <path d="M 20.492 3.06 C 20.492 2.751 20.241 2.502 19.932 2.502 C 19.771 2.503 19.617 2.576 19.512 2.701 L 19.507 2.698 L 11.498 12.059 L 9.13 9.911 L 8.378 10.74 L 11.6 13.66 L 20.356 3.423 L 20.353 3.42 C 20.44 3.32 20.49 3.192 20.492 3.06 Z" />
    </SvgIcon>
);
