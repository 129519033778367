import {NextPage} from 'next';
import {PageLayout} from '../client/common/components/layout/PageLayout';
import {Calendar} from '../client/components/calendar/Calendar';
import {EntryModal} from '../client/components/dialog/EntryModal';
import {useAppContext} from '../client/lib/context/AppContext';

type Props = {
    readonly statusCode?: number;
};
const Index: NextPage<Props> = ({statusCode}) => {
    const {
        values: {servisniZakazky},
    } = useAppContext();

    return (
        <PageLayout statusCode={statusCode}>
            <EntryModal />
            {servisniZakazky && <Calendar servisniZakazky={servisniZakazky} />}
        </PageLayout>
    );
};

export default Index;
