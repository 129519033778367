import {gql} from '@apollo/client';

export const Mutations = {
    gql: {
        ulozitTermin: gql`
            mutation OdeslaniTerminuMutation($input: ServisniZakazkySetInput!) {
                servisniZakazky {
                    set(input: $input)
                }
            }
        `,
    },
};
