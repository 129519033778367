import {ServisniZakazkyGet} from '@eon.cz/apollo13-graphql-web';
import {Body} from './Body';
import {Header} from './Header';

type Props = {
    readonly servisniZakazky: ServisniZakazkyGet | undefined;
};
export const Calendar = ({servisniZakazky}: Props) => {
    return (
        <>
            <Header servisniZakazky={servisniZakazky} />
            <Body servisniZakazky={servisniZakazky} />
        </>
    );
};
